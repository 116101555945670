<template>
  <div class="double-eleven">
    <div class="top">
      <square src="https://img.xinshu.me/resource/37fb04b004484ab2b54d82909f96b06d" plain
                  height="100%" radius="0"/>
      <div class="lamp" :class="{'lamp-light': showLamp}">
        活动享不停，福利送不停
      </div>
    </div>
    <div class="content">
      <div class="tab-box">
        <div class="activity-tab" :class="{'active': showTab === item.tag}" v-for="item in showTabList"
             :key="item.tag" @click="clickTab(item)">
          <div class="time">{{item.time}}</div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
      <div class="activity-box">
        <div class="activity-load" v-if="tabLoading">
          <fa icon="spinner" spin/>
          &nbsp;加载中...
        </div>
        <template v-else>
          <div class="content first" v-show="showTab === 0">
            <div class="activity-content">
              <div class="danmaku" v-if="danmakuList.length > 0">
                <swiper class="danmaku-swiper" :options="swiperOption">
                  <swiper-slide v-for="(item, idx) in danmakuList" :key="idx">
                    <div class="danmaku-item" v-if="item">
                      <span class="ava">
                        <img :src="item.headimgurl"/>
                      </span>
                      <span style="max-width: 50%;">{{item.name}}</span>
                      <span style="color: #fffd9a;">&nbsp;充值{{item.value}}元</span>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div class="activity-ti">
                <span>双11抢先购</span>
                <span class="activity-ti-color">充值就送大额券</span>
              </div>
              <div class="first-box">
                <square class="first-pic" src="https://img.xinshu.me/resource/10302aacc22e4706a80d5d1a9508ae36" plain
                  height="120%" radius="0"/>
                <square class="first-pic" src="https://img.xinshu.me/resource/bcc03fdb82ee452a986e2fdbbb700100" plain
                  height="120%" radius="0"/>
                <square class="first-pic" src="https://img.xinshu.me/resource/5f7f20b100234ee9bd59871d0a25ad8b" plain
                  height="120%" radius="0"/>
              </div>
              <div
                :class="{'activity-btn': tabList[0].open, 'activity-btn-unable': tabList[0].close || !tabList[0].open}"
                @click="clickFirst"
              >
                {{tabList[0].close ? '活动结束' : (!tabList[0].open ? '活动未开始' : '前往充值')}}
              </div>
            </div>
          </div>
          <div class="content second" v-show="showTab === 1">
            <div class="activity-content">
              <div class="danmaku" v-if="danmuList.length > 0">
                <swiper class="danmaku-swiper" :options="swiperOption">
                  <swiper-slide v-for="(item, idx) in danmuList" :key="idx">
                    <div class="danmaku-item" v-if="item">
                      <span class="ava">
                        <img :src="item.headimgurl"/>
                      </span>
                      <span style="max-width: 50%;">{{item.name}}</span>
                      <span style="color: #fffd9a;">&nbsp;{{item.info}}</span>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div class="activity-ti">
                <span>打卡满7天</span>
                <span class="activity-ti-color">2022年虎年台历免费送</span>
              </div>
              <div class="second-box">
                <img src="https://img.xinshu.me/resource/6c25968e24a149b7a229c20d04336265" alt="">
              </div>
              <div
                :class="{'activity-btn': tabList[1].open, 'activity-btn-unable': tabList[1].close || !tabList[1].open}"
                @click="clickSecond"
              >
                {{tabList[1].close ? '活动结束' : (!tabList[1].open ? '活动未开始' : '前往领取')}}
              </div>
            </div>
          </div>
          <div class="content third" v-show="showTab === 2">
            <div class="activity-content">
              <div class="activity-ti">
                <span>每天整点</span>
                <span class="activity-ti-color">1元包邮限量秒杀</span>
              </div>
              <div class="third-box">
                <div class="middle-pic" style="top: 24%; width: 74%;">
                  <img src="https://img.xinshu.me/resource/a1441b16e8364d82b7628f8a43f9b390" alt="">
                </div>
              </div>
              <div
                :class="{'activity-btn': tabList[2].open, 'activity-btn-unable': tabList[2].close || !tabList[2].open}"
                @click="clickThird"
              >
                {{tabList[2].close ? '活动结束' : (!tabList[2].open ? '活动未开始' : '前往秒杀')}}
              </div>
            </div>
          </div>
          <div class="content recharge" v-show="showTab === 3">
            <div class="activity-content">
              <div class="activity-ti">
                <span>全场8折</span>
                <span class="activity-ti-color">时光书点赞到5折 PDF全场5折</span>
              </div>
              <div class="third-box">
                <div class="middle-pic" style="width: 80%;">
                  <img src="https://img.xinshu.me/resource/fc10deb13b574f4181744848ae95eb01" alt="">
                </div>
              </div>
              <div
                :class="{'activity-btn': tabList[3].open, 'activity-btn-unable': tabList[3].close || !tabList[3].open}"
                @click="clickFourth"
              >
                {{tabList[3].close ? '活动结束' : (!tabList[3].open ? '活动未开始' : '前往书架')}}
              </div>
            </div>
          </div>
          <div class="content recharge" v-show="showTab === 4">
            <div class="activity-content">
              <div class="activity-ti">
                <span>照片书套餐</span>
                <span class="activity-ti-color">双11低至五折</span>
              </div>
              <div class="third-box">
                <div class="middle-pic" style="width: 80%;">
                  <img src="https://img.xinshu.me/resource/86a59ed89aa04e2fbfb4945d0cfa3ad3" alt="">
                </div>
              </div>
              <div
                :class="{'activity-btn': tabList[4].open, 'activity-btn-unable': tabList[4].close || !tabList[4].open}"
                @click="clickFifth"
              >
                {{tabList[4].close ? '活动结束' : (!tabList[4].open ? '活动未开始' : '前往购买套餐')}}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="bottom">
      <div class="recommend-box">
        <div class="subtitle"><img
          src="https://img.xinshu.me/resource/5fe0d8b2b92d4bf4a3099fa8404646c5" alt=""></div>
        <div class="item-outer">
          <div class="item-wrap">
            <div class="item" v-for="(val, idx) in recommendList" :key="idx" @click="jumpLink(val)">
              <img class="pic" :src="val.pic" alt="">
              <div class="ti">{{val.title}}</div>
              <div class="info">{{val.info}}</div>
              <div class="price">{{val.price}}<span class="qi"></span> <span class="old-price"
                                                                             v-if="val.oldPrice">{{val.oldPrice}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="gzhPopup" @close="gzhPopup = false">
      <b-card class="mb-0">
        <h5>活动期间</h5>
        <h5>在心书公众号回复</h5>
        <h5>“打卡双11”</h5>
        <h5>满7天即可获得</h5>
        <h5>2022虎年定制台历一本</h5>
        <square src="https://canvas.xinshu.me/qrcode?qrcode=https%3A%2F%2Fmp.weixin.qq.com%2Fcgi-bin%2Fshowqrcode%3Fticket%3DgQFu8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAycVZaV0VvYldieGUxMDAwMHcwN0kAAgSf-4BhAwQAAAAA&icon=weixinshu"
                style="max-width: 200px;" class="mx-auto"/>
        <div class="mt-3">
          长按识别二维码进入回复
        </div>
      </b-card>
    </popup>
    <popup v-if="xcxPopup" @close="xcxPopup = false">
      <b-card class="mb-0">
        <b-card-title>前往小程序秒杀</b-card-title>
        <square src="https://img.xinshu.me/resource/cc5cd03a8a1f48a8a0c5a368afde4e61"
                style="max-width: 200px;" class="mx-auto"/>
        <div class="mt-3">
          长按识别二维码，前往小程序秒杀
        </div>
      </b-card>
    </popup>
    <popup v-if="fifthPopup" @close="fifthPopup = false">
      <b-card class="mb-0">
        <b-card-title>前往小程序</b-card-title>
        <square src="https://img.xinshu.me/resource/cc5cd03a8a1f48a8a0c5a368afde4e61"
                style="max-width: 200px;" class="mx-auto"/>
        <div class="mt-3">
          长按识别二维码，前往小程序查看套餐
        </div>
      </b-card>
    </popup>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'

export default {
  name: 'weixinshu7th',
  title: '微信书7周年',
  mixins: [require('@/mixins/swiper').default],
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        }
      },
      tabList: [
        {
          tag: 0,
          time: '10.27-11.8',
          name: '充值送券',
          startTime: '2021/10/27 00:00:00',
          endTime: '2021/11/9 00:00:00',
          open: true,
          close: false
        },
        {
          tag: 1,
          time: '11.3-11.14',
          name: '免费台历',
          startTime: '2021/11/3 00:00:00',
          endTime: '2021/11/15 00:00:00',
          open: true,
          close: false
        }, {
          tag: 2,
          time: '11.9-11.12',
          name: '整点秒杀',
          startTime: '2021/11/9 00:00:00',
          endTime: '2021/11/13 00:00:00',
          open: true,
          close: false
        }, {
          tag: 3,
          time: '11.9-11.14',
          name: '全场狂欢',
          startTime: '2021/11/9 00:00:00',
          endTime: '2021/11/15 00:00:00',
          open: true,
          close: false
        }, {
          tag: 4,
          time: '11.9-11.14',
          name: '套餐半价',
          startTime: '2021/11/9 00:00:00',
          endTime: '2021/11/15 00:00:00',
          open: true,
          close: false
        }
      ],
      recommendList: [
        {
          pic: 'https://img.xinshu.me/upload/4b6188c050ed44e691dfebc51cdf4481',
          link: 'https://weixinshu.com/packages/IXXMRL',
          value: '锁线照片书',
          title: '锁线照片书3件套',
          info: '3本 | 每本95页',
          price: '￥228.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/4dc0f56daaad41a5bcceab284be5669f',
          link: 'https://weixinshu.com/packages/HESIQZ',
          value: '心书皮面册',
          title: '皮面册12件套',
          info: '12本 | 每本19页',
          price: '￥199.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/09fca68d53cf4516a2ee4d581d33f688',
          link: 'https://weixinshu.com/scenarios/50d8a15ba1c8c',
          value: '创意绘本(男孩版)',
          title: '创意绘本(男孩版)',
          info: '16页 | 上传照片自动抠图',
          price: '￥68.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/d0672f8e2ff14cba8ed80d5de24961ae',
          link: 'https://weixinshu.com/scenarios/a24e929f8849f',
          value: 'B5锁线书',
          title: '美好童年·B5锁线书',
          info: '95页 | 可传图95张',
          price: '￥98.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/a26b0af1ec9845d7887f12dd4a0de48d',
          link: 'https://weixinshu.com/scenarios/a678151d11f59',
          value: 'A5纪念册',
          title: '幸福一家·A5纪念册',
          info: '20~60页 | 可传图10~120张',
          price: '￥68.00起',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/3e29d664ecd943ad88cfde903e6e78d6',
          link: 'https://weixinshu.com/scenarios/aa9f854f30f77',
          value: 'B5照片书',
          title: '旅行意义·B5照片书',
          info: '20~60页 | 可传图20~240张',
          price: '￥58.00起',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/bd80912ea78f43f4b127612a9af52ded',
          link: 'https://weixinshu.com/scenarios/a24456ae2262e',
          value: '12寸写真集',
          title: '邂逅爱情·12寸写真集',
          info: '20页 | 可传图10~40张',
          price: '￥99.00',
          oldPrice: ''
        }, {
          pic: 'https://img.xinshu.me/upload/59ca998641e44fd0be2210487c6d2ca6',
          link: 'https://weixinshu.com/scenarios/f868734fa4dbc',
          value: 'A4杂志册',
          title: '青春不散·A4杂志册',
          info: '24页 | 可传图24~96张',
          price: '￥48.00',
          oldPrice: ''
        }
      ],
      showTab: 0,
      gzhPopup: false,
      xcxPopup: false,
      fifthPopup: false,
      showAllTabs: false,
      tabLoading: true,
      danmakuList: [],
      showLamp: true,
      timer: null,
      danmuList: [],
      time: '',
      showTabList: []
    }
  },
  computed: {
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    this.showAllTabs = this.$route.query.tab === 'all'
    this.time = this.$route.query.time
    this.init()
  },
  mounted() {
    this.timer = setInterval(() => {
      this.showLamp = !this.showLamp
    }, 1000)
    this.showTabList = this.tabList.slice(1)
  },
  methods: {
    init() {
      this.initShowTab()
      configShare({
        title: '微信书双十一',
        desc: '充值送券|免费台历|整点秒杀|全场狂欢',
        imgUrl: 'https://img.xinshu.me/upload/cab78072564b45c6844a10b0cd74e89d',
        link: location.href
      })
    },
    async initShowTab() {
      let show = this.$route.query.show
      if (show === '1' || show === '2' || show === '3' || show === '4') {
        show = parseInt(show)
        this.showTab = show - 1
        this.tabLoading = true
        await this.judgeTab(this.tabList[this.showTab])
        this.tabLoading = false
        return
      }
      const tab4time = [
        {
          tab: 0,
          start: '2021/10/27 00:00:00',
          end: '2021/11/8 23:59:59'
        }, {
          tab: 3,
          start: '2021/11/9 00:00:00',
          end: '2021/11/30 23:59:59'
        }
      ]
      const now = await this.getServerTime()
      tab4time.some(ele => {
        const start = this.$day(ele.start)
        const end = this.$day(ele.end)
        if (now > start && now <= end) {
          this.showTab = ele.tab
          return true
        } else {
          return false
        }
      })
      this.tabLoading = true
      await this.judgeTab(this.tabList[this.showTab])
      this.tabLoading = false
    },
    async clickTab(item) {
      this.showTab = item.tag
      this.tabLoading = true
      await this.judgeTab(item)
      this.tabLoading = false
    },
    async judgeTab(item) {
      if (item.tag === 0) {
        this.getDanmaku()
      }
      if (item.tag === 1) {
        this.getDanmu()
      }
      const now = await this.getServerTime()
      const closeTime = this.$day(item.endTime)
      item.close = now > closeTime
      if (this.showAllTabs) {
        item.open = true
        return
      }
      const openTime = this.$day(item.startTime)
      item.open = now > openTime
    },
    async getServerTime() {
      let now = ''
      try {
        const result = await this.$req.get('/api/activity/server_time')
        now = this.$day(result.now)
        if (this.time !== '') {
          now = this.$day(this.time)
        }
        // now = this.$day('2021/11/8 15:00:00')
      } catch (error) {
        now = this.$day()
      }
      return now
    },
    clickFirst() {
      if (this.tabList[0].close || !this.tabList[0].open) {
        return
      }
      this.$router.push('/my/recharge')
    },
    clickSecond() {
      if (this.tabList[1].close || !this.tabList[1].open) {
        return
      }
      this.gzhPopup = true
    },
    clickThird() {
      if (this.tabList[2].close || !this.tabList[2].open) {
        return
      }
      if (window.wx) { // 在微信环境
        if (this.isMiniProgram) { // 在小程序
          window.wx.miniProgram.navigateTo({url: '/pages/seckill/seckill'})
        } else {
          window.open('weixin://dl/business/?t=oUTa0W4u6Hs')
        }
      } else {
        this.xcxPopup = true
      }
    },
    clickFourth() {
      if (this.tabList[3].close || !this.tabList[3].open) {
        return
      }
      this.$router.push('/books')
    },
    clickFifth() {
      if (this.tabList[4].close || !this.tabList[4].open) {
        return
      }
      if (window.wx) { // 在微信环境
        if (this.isMiniProgram) { // 在小程序
          window.wx.miniProgram.navigateTo({url: '/pages/categories/categories?menu=packages'})
        } else {
          window.open('weixin://dl/business/?t=zbDfrGTPQTd')
        }
      } else {
        this.fifthPopup = true
      }
    },
    async getDanmaku() {
      const result = await this.$req.get('/api/activity/double11/giftcards')
      result.forEach(ele => {
        ele.name = ele.nickname.substr(0, 1) + '***' + ele.nickname.substr(ele.nickname.length - 1, 1)
      })
      this.danmakuList = result
    },
    async getDanmu() {
      const result = await this.$req.get('/api/activity/double11/check_ins')
      result.forEach(ele => {
        const sec = ele.interval
        let str = ''
        if (sec < 60) {
          str = `${sec}秒前打卡`
        } else if (sec >= 60 && sec < 3600) {
          const min = Math.floor(sec / 60)
          str = `${min}分钟前打卡`
        } else if (sec >= 3600) {
          const h = Math.floor(sec / 3600)
          str = `${h}小时前打卡`
        }
        ele.info = str
        ele.name = ele.nickname.substr(0, 1) + '***' + ele.nickname.substr(ele.nickname.length - 1, 1)
      })
      this.danmuList = result
    },
    jumpLink(item) {
      if (item.link) {
        if (item.link.startsWith('https://weixinshu.com')) {
          item.link = item.link.replace('https://weixinshu.com', '')
        }
        if (item.link.startsWith('/')) {
          this.$gtag.event('weixinshu7th_link', {
            event_label: item.value
          })
          return this.$router.push(item.link)
        }
        location.href = item.link
        this.$gtag.event('weixinshu7th_link', {
          event_label: item.value
        })
      }
    }
  }
}
</script>

<style lang="scss">
[data-page="doubleEleven2021"] {
  main {
    padding-bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
$themeColor: #9D2221;
$secondColor: #FAE2B3;
.double-eleven {
  max-width: 480px;
  padding: 0;
  background-color: #C32B27;

  .top {
    position: relative;

    .lamp {
      position: absolute;
      bottom: 3%;
      left: 0;
      right: 0;
      width: 80%;
      background-image: url(https://img.xinshu.me/resource/57e1e1e0d64d4d79af035d57d8ff67e6);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100% 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FAE2B3;
      font-size: 18px;
    }
    .lamp::after {
      content: "";
      display: block;
      /*这里的padding =  height / width*/
      padding-bottom: 20%;
    }
    .lamp-light {
      background-image: url(https://img.xinshu.me/resource/36366cf1081340bfad5bf768e5c758fb);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100% 100%;
    }
  }

  .content {
    .tab-box {
      padding: 1px 0 5px 0;
      background-color: #EE3F36;
      display: flex;
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      .activity-tab {
        width: 25%;
        max-width: 25%;
        min-width: 25%;
        background-color: #C32B27;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FAE2B3;
        font-size: 12px;
        padding: 0.5rem 0;
        cursor: pointer;
        border-width: 1px;
        border-color: #EE3F36;
        border-style: none solid solid none;

        &:last-child {
          border-right: none;
        }
      }

      .active {
        background-color: #FBEFC9;
        color: #9D2221;
      }
    }

    .activity-box {
      position: relative;
      height: 300px;
      min-height: 240px;
      background-color: #C32B27;

      .activity-load {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FAE2B3;
      }

      .activity-blank {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .blank-pic {
          width: 26%;
        }

        .blank-text {
          color: #FAE2B3;
          margin-top: 12px;
        }
      }

      .content {
        height: 100%;

        .activity-rule {
          position: absolute;
          width: 18%;
          right: 0;
          top: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 12px;

          .text {
            position: absolute;
            color: #fff;
          }
        }

        .danmaku {
          position: absolute;
          top: 20%;
          color: #fff;
          height: 30px;
          line-height: 30px;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 50em;
          font-size: 12px;
          padding: 0 12px;
          width: 48%;
          left: 0;
          right: 0;
          margin: 0 auto;

          .danmaku-swiper {
            height: 100%;
          }

          .danmaku-item {
            height: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            .ava {
              height: 30px;
              margin-right: 4px;
              img {
                border-radius: 50%;
                max-width: 24px;
                margin-bottom: 1%;
              }
            }
          }
        }

        .activity-ti {
          position: absolute;
          width: 100%;
          top: 6%;
          text-align: center;
          font-size: 16px;
          color: #fff;
        }

        .middle-pic {
          position: absolute;
          width: 50%;
          top: 26%;
          // bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .activity-ti-color {
          color: rgb(255, 253, 154);
        }

        .activity-btn {
          position: absolute;
          width: 13em;
          background-image: url(https://img.xinshu.me/resource/376be2c1e83b4330b2cc9a134e1b41fb);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: top center;
          margin: auto;
          left: 0;
          right: 0;
          text-align: center;
          color: #9D2221;
          padding: 10px 0;
          bottom: 8%;
          cursor: pointer;
          font-weight: bold;
        }

        .activity-btn-unable {
          position: absolute;
          width: 13em;
          background-image: url(https://img.xinshu.me/resource/4d32f8dfb68a4780ab59eed993475a25);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: top center;
          margin: auto;
          left: 0;
          right: 0;
          text-align: center;
          padding: 10px 0;
          bottom: 8%;
          cursor: pointer;
          font-weight: bold;
          color: rgb(90, 81,81);
        }
      }

      .first {
        .first-box {
          display: flex;
          width: 80%;
          position: absolute;
          top: 38%;
          left: 0;
          right: 0;
          margin: 0 auto;
          justify-content: space-around;

          .first-pic {
            width: 26%;
          }
        }
      }

      .second {
        .second-box {
          width: 47%;
          position: absolute;
          top: 31%;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

  }

  .recommend-box {
      width: 100%;
      background-color: #EE3F36;

      .subtitle {
        width: 62%;
        margin-left: 19%;
        padding-top: 30px;
      }

      .item-outer {
        width: 100%;
        margin-top: 30px;
        padding: 0 2rem;

        .item-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item {
            flex: 1;
            width: 48%;
            min-width: 48%;
            max-width: 48%;
            margin-bottom: 1.5rem;
            color: #fff;
            font-size: 14px;

            .pic {
              border-radius: 8px;
            }

            .ti {
              margin-top: 10px;
            }

            .info {
              font-size: 10px;
              margin: 4px 0;
            }

            .price {
              .qi {
                font-size: 10px;
              }

              .old-price {
                font-size: 10px;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
}
</style>
